import Swiper from 'swiper'
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Grid,
  Thumbs,
} from 'swiper/modules'
import { media } from './functions/media'
import { checkQuizSlide } from './quiz'

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

export default (): void => {
  new window.Swiper('.clients-slider .swiper', {
    pagination: {
      el: '.clients-slider .swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.clients-slider .swiper-button-prev',
      nextEl: '.clients-slider .swiper-button-next',
    },
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    speed: 500,
    grabCursor: true,
    loop: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.5,
      },
      [media.md]: {
        slidesPerView: 2,
      },
      [media.lg]: {
        slidesPerView: 3,
        spaceBetween: 28,
      },
    },
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  }) as Swiper

  new window.Swiper('.quiz-slider .swiper', {
    navigation: {
      prevEl: '.quiz-slider .swiper-button-prev',
      nextEl: '.quiz-slider .swiper-button-next',
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 30,
    allowTouchMove: false,
    watchSlidesProgress: true,
    on: {
      slideChange: (swiper: Swiper): void => {
        checkQuizSlide(swiper.visibleSlides[0])

        switch (
          swiper.visibleSlides[0] === swiper.slides[swiper.slides.length - 1]
        ) {
          case true: {
            ;(swiper.el.closest('[data-quiz]') as HTMLElement).setAttribute(
              'data-quiz-end',
              ''
            )
            break
          }

          case false: {
            ;(swiper.el.closest('[data-quiz]') as HTMLElement).removeAttribute(
              'data-quiz-end'
            )
            break
          }
        }
      },
    },
  }) as Swiper

  new window.Swiper('.documents-slider .swiper', {
    pagination: {
      el: '.documents-slider .swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.documents-slider .swiper-button-prev',
      nextEl: '.documents-slider .swiper-button-next',
    },
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    speed: 500,
    grabCursor: true,
    loop: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.5,
        spaceBetween: 24,
      },
      [media.md]: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
      [media.lg]: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  }) as Swiper
}
