import { Coordinates } from './functions/coordinates'
import { touchDevice } from './functions/touch-device'

export default (): void => {
  const parallaxes = document.querySelectorAll(
    '*[data-parallax]'
  ) as NodeListOf<HTMLElement>

  parallaxes.forEach((parallax: HTMLElement): void => {
    if (!parallax || touchDevice()) return
    const hovereds = parallax.querySelectorAll(
      '*[data-parallax-hovered]'
    ) as NodeListOf<HTMLElement>

    hovereds.forEach((hovered: HTMLElement): void => {
      if (!hovered) return

      const perspective: number = hovered.dataset.parallaxHovered
        ? Number(hovered.dataset.parallaxHovered)
        : 600
      const items = hovered.querySelectorAll(
        '*[data-parallax-item]'
      ) as NodeListOf<HTMLElement>
      const depth = 10
      let positionY = 0
      let positionX = 0

      hovered.style.perspective = `${perspective}px`

      items.forEach((item: HTMLElement): void => {
        if (!item) return

        const translateZ = item.dataset.parallaxItem
          ? Number(item.dataset.parallaxItem)
          : 100

        item.style.transform = `rotateX(var(--rotate-x)) rotateY(var(--rotate-y)) translateZ(${translateZ}px)`
      })

      hovered.addEventListener('mousemove', ((event: MouseEvent): void => {
        const rect = (
          event.target as HTMLElement
        ).getBoundingClientRect() as DOMRect
        const coordinates: Coordinates = {
          top: (event.clientY - rect.top) / rect.height,
          left: (event.clientX - rect.left) / rect.width,
        }

        positionY = coordinates.left * (depth * 2) - depth
        positionX = coordinates.top * (depth * 2) - depth

        hovered.style.setProperty('--rotate-y', `${-positionY}deg`)
        hovered.style.setProperty('--rotate-x', `${positionX}deg`)
      }) as EventListener)

      hovered.addEventListener('mouseleave', ((): void => {
        hovered.style.setProperty('--rotate-y', '0')
        hovered.style.setProperty('--rotate-x', '0')
      }) as EventListener)
    })
  })
}
