export default (): void => {
  const preloader = document.querySelector(
    '*[data-preloader]'
  ) as HTMLDivElement

  if (!preloader) return

  const glass = preloader.querySelector(
    '*[data-preloader-glass]'
  ) as HTMLDivElement
  const counter = preloader.querySelector(
    '*[data-preloader-counter]'
  ) as HTMLSpanElement
  let count = 0

  const interval = setInterval((): void => {
    ++count
    glass.style.height = `${count}%`
    glass.style.fontSize = `${0.4 * count}px`
    counter.innerText = `${count}%`

    if (count === 100) {
      clearInterval(interval)
      preloader.classList.add('invisible', 'opacity-0')
      setTimeout((): void => preloader.remove(), 500)
    }
  }, 20) as NodeJS.Timeout
}
